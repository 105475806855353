import React from 'react';

function Popup({ show, onClose, imageUrl }) {
  if (!show) return null; // Si el estado "show" es false, no se renderiza el popup

  const handleOverlayClick = (e) => {
    // Si el clic ocurre fuera del contenido del pop-up (la caja blanca), cerramos el pop-up
    if (e.target.classList.contains('popup-overlay')) {
      onClose();
    }
  };

  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content">
      <button className="popup-close" onClick={onClose}>
          ×
        </button>
        <img src={imageUrl} alt="Popup" className="popup-image" />
      </div>
    </div>
  );
}

export default Popup;
