import React from 'react'

function MainCaribe() {
    return (
        <>
        

    <section className="section-main-destination">
    {/* <img src="http://mecamx.net/exodus2020/africa/img/slider-principal/slider-principal.jpg" alt="" className="section-main__image"/>*/}
        <h1 class="h1-title-section">Viajes al Caribe</h1>
        <div className="section-filtro"></div>
    </section>
        
        <section className="section-mega-offers-dest">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                
                <iframe className="iframe-mega" title="caribe" src="https://q.megatravel.com.mx/tools/vi.php?Dest=10&txtColor=000&aColor=000&ahColor=02557f&thBG=02557f&thTxColor=fff&ff=3" width="100%" height="1200" border="0"align="left" allowtransparency="true" frameborder="0"> </iframe>
                </div> 
            </div> 
        </div>   
        </section>
      
        </>
    )
}

export default MainCaribe
